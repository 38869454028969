<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="Blog Left Sidebar" />

        <BlogMainWrapper />

        <BrandCarousel addClass="grey-bg" />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <!-- <back-to-top class="scroll-top" bottom="180px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top> -->
        <!-- back to top end -->
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import BlogMainWrapper from '../components/sections/BlogMainWrapper'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            Breadcrumb,
            BlogMainWrapper,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Blog Left Sidebar',
                        active: true
                    }
                ]
            }
        },
        metaInfo: {
            title: 'Castro - Blog Left Sidebar',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

